<template>
  <v-app>
    <v-app-bar app dark color="black">
      <div class="d-flex align-center pl-6">
        <h2>
          {{ title }}
        </h2>
      </div>

      <v-spacer />

      <v-btn icon @click="onClose">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view v-if="state.userInfo.email" />

      <div v-else class="d-flex align-center justify-center h-full">
        <v-progress-circular indeterminate size="48" />
      </div>

      <v-overlay
        class="align-center justify-center"
        :model-value="state.loading || auctionHasFinished"
      >
        <v-progress-circular
          v-if="state.loading"
          color="primary"
          indeterminate
          size="48"
        />
      </v-overlay>
    </v-main>

    <v-snackbar
      v-model="snackbar.isOpened"
      :timeout="5000"
      bottom
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <span v-if="snackbar.subtext" class="d-block text-small">
        {{ snackbar.subtext }}
      </span>
      <template v-slot:actions>
        <v-btn icon @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

const auctionHasFinished = ref<Boolean>(false);
const { state, commit, dispatch } = useStore();

const title = computed(() => {
  const result = state.auction.kind.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
});
const snackbar = computed(() => {
  return state.snackbar;
});

function onClose() {
  dispatch("close");
}

function closeSnackbar() {
  commit("closeSnackbar");
}

function setCredentials(credentials: { [x: string]: string }) {
  Object.keys(credentials).forEach((key) => {
    localStorage.setItem(key, credentials[key]);
  });

  dispatch("fetchUserInfo");
}

onMounted(() => {
  window.addEventListener("message", (ev) => {
    const action = ev?.data?.action;

    switch (action) {
      case "credentials":
        setCredentials(ev.data.data);
        break;
      case "auction-info":
        commit("setAuctionInfo", ev.data.data);
        dispatch("fetchSale");
        break;
      case "auction-has-finished":
        auctionHasFinished.value = true;
        commit("errorNotification", {
          text:
            "The auction has already finished. You are no longer able to submit a bid for this asset. " +
            "Please contact listing agent for more details.",
        });
        setTimeout(() => {
          dispatch("close");
        }, 5000);
        break;
    }
  });
});
</script>
