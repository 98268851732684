import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column" }

import { computed, unref } from "vue";
import { Utils } from "@/utils";
import { validators } from "@/utils/validators";

//Emits

interface IProps {
  modelValue: number;
  step: number;
  min: number;
  disabled: boolean;
  disableErrors: boolean;
}

// Props


export default /*@__PURE__*/_defineComponent({
  __name: 'AppBidInput',
  props: {
    modelValue: { default: 0 },
    step: { default: 0 },
    min: { default: 0 },
    disabled: { type: Boolean, default: false },
    disableErrors: { type: Boolean, default: false }
  },
  emits: ["input:modelValue"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;
// Types

const props = __props;

// Computed

const onFormattedValue = computed(() => {
  return Utils.formatPrice(props.modelValue);
});
const error = computed(() => {
  return validators.moreOrEquals(props.modelValue, props.min + props.step);
});

const errorMessages = computed((): string[] =>
  [unref(error)].filter((err): err is string => typeof err !== "boolean")
);

// Methods

function onInput(ev: string) {
  const parsed = Number(ev.replaceAll(/[^0-9.]/g, ""));
  emit("input:modelValue", parsed);
}

function increaseBid() {
  emit("input:modelValue", props.modelValue + props.step);
}

function decreaseBid() {
  const decreased = props.modelValue - props.step;

  if (decreased > props.min) {
    emit("input:modelValue", decreased);
  } else {
    emit("input:modelValue", props.min + props.step);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_text_field = _resolveComponent("v-text-field")!

  return (_openBlock(), _createBlock(_component_v_text_field, {
    class: "app-bid-input",
    variant: "solo",
    disabled: _ctx.disabled,
    rules: [() => error.value],
    "error-messages": errorMessages.value,
    value: onFormattedValue.value,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (onInput($event.target.value)))
  }, {
    append: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_icon, { onClick: increaseBid }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("mdi-menu-up")
          ])),
          _: 1
        }),
        _createVNode(_component_v_icon, { onClick: decreaseBid }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("mdi-menu-down")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["disabled", "rules", "error-messages", "value"]))
}
}

})