<template>
  <v-text-field
    class="app-bid-input"
    variant="solo"
    :disabled="disabled"
    :rules="[() => error]"
    :error-messages="errorMessages"
    :value="onFormattedValue"
    @input="onInput($event.target.value)"
  >
    <template v-slot:append>
      <div class="d-flex flex-column">
        <v-icon @click="increaseBid">mdi-menu-up</v-icon>
        <v-icon @click="decreaseBid">mdi-menu-down</v-icon>
      </div>
    </template>
  </v-text-field>
</template>

<script setup lang="ts">
import { defineProps, computed, withDefaults, defineEmits, unref } from "vue";
import { Utils } from "@/utils";
import { validators } from "@/utils/validators";

//Emits

const emit = defineEmits(["input:modelValue"]);
// Types

interface IProps {
  modelValue: number;
  step: number;
  min: number;
  disabled: boolean;
  disableErrors: boolean;
}

// Props

const props = withDefaults(defineProps<IProps>(), {
  modelValue: 0,
  step: 0,
  min: 0,
  disabled: false,
  disableErrors: false,
});

// Computed

const onFormattedValue = computed(() => {
  return Utils.formatPrice(props.modelValue);
});
const error = computed(() => {
  return validators.moreOrEquals(props.modelValue, props.min + props.step);
});

const errorMessages = computed((): string[] =>
  [unref(error)].filter((err): err is string => typeof err !== "boolean")
);

// Methods

function onInput(ev: string) {
  const parsed = Number(ev.replaceAll(/[^0-9.]/g, ""));
  emit("input:modelValue", parsed);
}

function increaseBid() {
  emit("input:modelValue", props.modelValue + props.step);
}

function decreaseBid() {
  const decreased = props.modelValue - props.step;

  if (decreased > props.min) {
    emit("input:modelValue", decreased);
  } else {
    emit("input:modelValue", props.min + props.step);
  }
}
</script>

<style scoped lang="scss">
$height: 42px;

.app-bid-input {
  max-height: $height;
  &:deep {
    .v-input__control {
      max-height: $height;
    }
    .v-field__field {
      max-height: $height !important;
      height: $height !important;
      & .v-field__input {
        padding: 0 12px;
        max-height: $height !important;
        height: $height !important;
        min-height: $height !important;
      }
    }
    .v-input__append {
      padding: 0 !important;
      max-height: $height !important;
      margin-left: -36px !important;
      align-items: center;
    }
  }
}
</style>
