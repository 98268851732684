import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AppBidInput from "@/components/App/AppBidInput";
import { getFormattedCurrency } from "@/utils/formatCurrency";

import {
  ref,
  unref,
  onMounted,
  onBeforeUnmount,
  computed,
} from "vue";
import subscriptions from "@/api/subscriptions";
import EventBus, { EVENT_BUS_EVENTS } from "@/eventBus";

import type { ComputedRef } from "vue";
import type { GraphQLResult } from "@aws-amplify/api";
import type { EnglishAuction, OnSetCurrentBidSubscription } from "@/API";
import type { IFormData } from "@/types/bids";

import { useStore } from "vuex";
import useCountdown from "@/composables/useCountdown";

import { VForm } from "vuetify/components";

export default /*@__PURE__*/_defineComponent({
  __name: 'BidFormEnglish',
  emits: ["update"],
  setup(__props, { emit: __emit }) {

const { state, commit, dispatch, getters } = useStore();

const emit = __emit;
const form = ref<InstanceType<typeof VForm>>();

// Data
const valid = ref<Boolean>(false);
const formData = ref<IFormData>({
  yourBid: 0,
  yourMaxBid: 0,
  autoBidding: false,
});
const startingPrice = ref<number>(0);
const currentBidWarning = ref<boolean>(false);

const prevBidValue = ref<number | null>(null);
const onSetCurrentBidSub = ref<any>(null);
const onEditAuctionSub = ref<any>(null);

// Computed
const isCurrent = computed(() => {
  const {
    sale = {
      kind: "",
    },
  } = state ?? {};
  return sale[sale.kind]?.currentBidOwner === getters.getUserId;
});

const getFormattedStartingPrice = computed(() => {
  return getFormattedCurrency(unref(startingPrice));
});

const auction: ComputedRef<EnglishAuction> = computed(() => {
  return state.sale.englishAuction;
});
// Composables
const { countdown } = useCountdown(auction);

// Methods
function calculateStartingPrice() {
  const { currentBidValue = 0, startingPrice: auctionStartingPrice = 0 } =
    unref(auction) ?? {};
  startingPrice.value = currentBidValue || auctionStartingPrice || 0;
}

async function validate() {
  const _form = unref(form);
  if (!_form) {
    return;
  }

  await form?.value?.validate();
  if (!unref(valid)) {
    return;
  }

  if (unref(prevBidValue) === unref(formData)?.yourBid) {
    commit("errorNotification", {
      text: "It's impossible to place two bids with the same value",
      closable: true,
    });
    return;
  }

  if (unref(isCurrent)) {
    currentBidWarning.value = true;
    return;
  }

  placeBid();
}

async function placeBid() {
  currentBidWarning.value = false;
  const { yourBid = 1 } = unref(formData);
  await dispatch("createAuenBid", {
    bid: {
      saleId: state.saleId,
      bidValue: yourBid,
    },
  });

  let subtext = "Your bid has been successfully submitted";

  if (getters.agentFullName) {
    subtext += ` to ${getters.agentFullName}`;
  }

  commit("successNotification", {
    text: "Thank you!",
    subtext,
    closable: true,
  });

  emit("update");
  prevBidValue.value = yourBid;
}

function onCurrentBidClick() {
  if (isCurrent.value) return;
  const bidIncrement = unref(auction)?.bidIncrement || 0;
  formData.value.yourBid = unref(startingPrice) + bidIncrement;
}

//Hooks

onMounted(async () => {
  const { id = "" } = state.sale;
  onSetCurrentBidSub.value = await subscriptions.onSetCurrentBid({
    id,
  });
  onSetCurrentBidSub.value = onSetCurrentBidSub.value.subscribe({
    next: (value: GraphQLResult<OnSetCurrentBidSubscription>) => {
      commit(
        "setCurrentBidValue",
        value?.data?.onSetCurrentBid?.englishAuction?.currentBidValue
      );
      commit(
        "setCurrentBidOwner",
        value?.data?.onSetCurrentBid?.englishAuction?.currentBidOwner
      );
      calculateStartingPrice();
    },
  });
  onEditAuctionSub.value = await subscriptions.onEditAuction({
    id,
  });
  onEditAuctionSub.value = onEditAuctionSub.value.subscribe(
    (englishAuction?: EnglishAuction) => {
      if (!englishAuction) return;
      const {
        startingPrice = "",
        endDate = "",
        bidIncrement = 1,
      } = englishAuction ?? {};
      const updates: {
        endDate?: string;
        bidIncrement?: number;
        startingPrice?: number;
      } = {};
      if (endDate) {
        updates.endDate = endDate;
      }
      if (bidIncrement) {
        updates.bidIncrement = bidIncrement;
      }
      if (startingPrice) {
        updates.startingPrice = startingPrice;
      }
      commit("updateEnglishAuction", updates);
      calculateStartingPrice();
    }
  );
  calculateStartingPrice();
  formData.value.yourBid = formData.value.yourMaxBid =
    unref(startingPrice) + (unref(auction).bidIncrement || 0);
  EventBus.$on(EVENT_BUS_EVENTS.NEXT_STEP, validate);
});

onBeforeUnmount(() => {
  if (onSetCurrentBidSub.value) {
    onSetCurrentBidSub.value.unsubscribe();
  }
  if (onEditAuctionSub.value) {
    onEditAuctionSub.value.unsubscribe();
  }
  EventBus.$off(EVENT_BUS_EVENTS.NEXT_STEP, validate);
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_unref(VForm), {
    ref_key: "form",
    ref: form,
    modelValue: valid.value,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((valid).value = $event)),
    class: "form",
    "lazy-validation": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            class: "d-flex flex-column",
            style: {"position":"relative"},
            cols: "12",
            md: "4"
          }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "mb-1" }, "Current Bid", -1)),
              _createVNode(_component_v_btn, {
                "x-large": "",
                height: "42",
                color: isCurrent.value ? 'success' : 'error',
                onClick: onCurrentBidClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(getFormattedStartingPrice.value), 1)
                ]),
                _: 1
              }, 8, ["color"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            class: "d-flex flex-column mt-4 mt-md-0",
            cols: "12",
            md: "4"
          }, {
            default: _withCtx(() => [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "mb-1" }, "Your Bid", -1)),
              _createVNode(_unref(AppBidInput), {
                "onInput:modelValue": _cache[0] || (_cache[0] = ($event: any) => (formData.value.yourBid = $event)),
                modelValue: formData.value.yourBid,
                disabled: formData.value.autoBidding,
                min: startingPrice.value,
                "disable-errors": isCurrent.value,
                step: auction.value.bidIncrement || 0
              }, null, 8, ["modelValue", "disabled", "min", "disable-errors", "step"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, {
            class: "d-flex flex-column",
            cols: "12",
            md: "4"
          }, {
            default: _withCtx(() => [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "mb-1" }, "Time Left", -1)),
              _createVNode(_component_v_text_field, {
                value: _unref(countdown).value,
                readonly: "",
                variant: "solo"
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_spacer),
      _createVNode(_component_v_dialog, {
        modelValue: currentBidWarning.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((currentBidWarning).value = $event)),
        width: "500"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_title, { class: "headline" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" You are current auction leader ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_card_text, { class: "subline" }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Do you want to submit higher bid anyway? ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_divider),
              _createVNode(_component_v_card_actions, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    color: "black",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (currentBidWarning.value = false))
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" No ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_v_btn, {
                    color: "black",
                    variant: "flat",
                    onClick: placeBid
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Yes ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})