<template>
  <div class="bid d-flex px-2">
    <v-row>
      <v-col cols="12">
        <bid-step-card title="Place You Bid">
          <place-you-bid />
        </bid-step-card>

        <bid-actions :is-my-bid-current="isMyBidCurrent" @next="onNextClick" />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
export default { name: "BidComponent" };
</script>

<script setup lang="ts">
import BidStepCard from "@/components/Bid/BidStepCard";
import PlaceYouBid from "@/components/PlaceYouBid";
import BidActions from "@/components/Bid/BidActions";
import EventBus, { EVENT_BUS_EVENTS } from "@/eventBus";

import { useStore } from "vuex";
import { computed } from "vue";

const { state, getters } = useStore();

function onNextClick() {
  EventBus.$emit(EVENT_BUS_EVENTS.NEXT_STEP);
}

const isMyBidCurrent = computed(() => {
  const {
    sale = {
      kind: "",
    },
  } = state ?? {};
  return sale[sale.kind]?.currentBidOwner === getters.getUserId;
});
</script>
