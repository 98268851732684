import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-8" }




export default /*@__PURE__*/_defineComponent({
  __name: 'BidStepCard',
  props: {
  title: {
    type: String,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "pa-5 bid-step-card d-flex flex-column" }, {
    default: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(__props.title), 1),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}
}

})