<template>
  <v-form ref="form" v-model="valid" class="form" lazy-validation>
    <v-row>
      <v-col
        class="d-flex flex-column"
        style="position: relative"
        cols="12"
        md="4"
      >
        <span class="mb-1">Current Bid</span>
        <v-btn
          x-large
          height="42"
          :color="isCurrent ? 'success' : 'error'"
          @click="onCurrentBidClick"
        >
          {{ getFormattedStartingPrice }}
        </v-btn>
      </v-col>

      <v-col class="d-flex flex-column mt-4 mt-md-0" cols="12" md="4">
        <span class="mb-1">Your Bid</span>
        <app-bid-input
          @input:modelValue="formData.yourBid = $event"
          :modelValue="formData.yourBid"
          :disabled="formData.autoBidding"
          :min="startingPrice"
          :disable-errors="isCurrent"
          :step="auction.bidIncrement || 0"
        />
      </v-col>

      <v-col class="d-flex flex-column" cols="12" md="4">
        <span class="mb-1">Time Left</span>
        <v-text-field :value="countdown.value" readonly variant="solo" />
      </v-col>
    </v-row>
    <v-spacer />

    <v-dialog v-model="currentBidWarning" width="500">
      <v-card>
        <v-card-title class="headline">
          You are current auction leader
        </v-card-title>

        <v-card-text class="subline">
          Do you want to submit higher bid anyway?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="black" @click="currentBidWarning = false"> No </v-btn>
          <v-btn color="black" variant="flat" @click="placeBid"> Yes </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script setup lang="ts">
import AppBidInput from "@/components/App/AppBidInput";
import { getFormattedCurrency } from "@/utils/formatCurrency";

import {
  ref,
  unref,
  onMounted,
  onBeforeUnmount,
  computed,
  defineEmits,
} from "vue";
import subscriptions from "@/api/subscriptions";
import EventBus, { EVENT_BUS_EVENTS } from "@/eventBus";

import type { ComputedRef } from "vue";
import type { GraphQLResult } from "@aws-amplify/api";
import type { EnglishAuction, OnSetCurrentBidSubscription } from "@/API";
import type { IFormData } from "@/types/bids";

import { useStore } from "vuex";
const { state, commit, dispatch, getters } = useStore();

const emit = defineEmits(["update"]);
import useCountdown from "@/composables/useCountdown";

import { VForm } from "vuetify/components";
const form = ref<InstanceType<typeof VForm>>();

// Data
const valid = ref<Boolean>(false);
const formData = ref<IFormData>({
  yourBid: 0,
  yourMaxBid: 0,
  autoBidding: false,
});
const startingPrice = ref<number>(0);
const currentBidWarning = ref<boolean>(false);

const prevBidValue = ref<number | null>(null);
const onSetCurrentBidSub = ref<any>(null);
const onEditAuctionSub = ref<any>(null);

// Computed
const isCurrent = computed(() => {
  const {
    sale = {
      kind: "",
    },
  } = state ?? {};
  return sale[sale.kind]?.currentBidOwner === getters.getUserId;
});

const getFormattedStartingPrice = computed(() => {
  return getFormattedCurrency(unref(startingPrice));
});

const auction: ComputedRef<EnglishAuction> = computed(() => {
  return state.sale.englishAuction;
});
// Composables
const { countdown } = useCountdown(auction);

// Methods
function calculateStartingPrice() {
  const { currentBidValue = 0, startingPrice: auctionStartingPrice = 0 } =
    unref(auction) ?? {};
  startingPrice.value = currentBidValue || auctionStartingPrice || 0;
}

async function validate() {
  const _form = unref(form);
  if (!_form) {
    return;
  }

  await form?.value?.validate();
  if (!unref(valid)) {
    return;
  }

  if (unref(prevBidValue) === unref(formData)?.yourBid) {
    commit("errorNotification", {
      text: "It's impossible to place two bids with the same value",
      closable: true,
    });
    return;
  }

  if (unref(isCurrent)) {
    currentBidWarning.value = true;
    return;
  }

  placeBid();
}

async function placeBid() {
  currentBidWarning.value = false;
  const { yourBid = 1 } = unref(formData);
  await dispatch("createAuenBid", {
    bid: {
      saleId: state.saleId,
      bidValue: yourBid,
    },
  });

  let subtext = "Your bid has been successfully submitted";

  if (getters.agentFullName) {
    subtext += ` to ${getters.agentFullName}`;
  }

  commit("successNotification", {
    text: "Thank you!",
    subtext,
    closable: true,
  });

  emit("update");
  prevBidValue.value = yourBid;
}

function onCurrentBidClick() {
  if (isCurrent.value) return;
  const bidIncrement = unref(auction)?.bidIncrement || 0;
  formData.value.yourBid = unref(startingPrice) + bidIncrement;
}

//Hooks

onMounted(async () => {
  const { id = "" } = state.sale;
  onSetCurrentBidSub.value = await subscriptions.onSetCurrentBid({
    id,
  });
  onSetCurrentBidSub.value = onSetCurrentBidSub.value.subscribe({
    next: (value: GraphQLResult<OnSetCurrentBidSubscription>) => {
      commit(
        "setCurrentBidValue",
        value?.data?.onSetCurrentBid?.englishAuction?.currentBidValue
      );
      commit(
        "setCurrentBidOwner",
        value?.data?.onSetCurrentBid?.englishAuction?.currentBidOwner
      );
      calculateStartingPrice();
    },
  });
  onEditAuctionSub.value = await subscriptions.onEditAuction({
    id,
  });
  onEditAuctionSub.value = onEditAuctionSub.value.subscribe(
    (englishAuction?: EnglishAuction) => {
      if (!englishAuction) return;
      const {
        startingPrice = "",
        endDate = "",
        bidIncrement = 1,
      } = englishAuction ?? {};
      const updates: {
        endDate?: string;
        bidIncrement?: number;
        startingPrice?: number;
      } = {};
      if (endDate) {
        updates.endDate = endDate;
      }
      if (bidIncrement) {
        updates.bidIncrement = bidIncrement;
      }
      if (startingPrice) {
        updates.startingPrice = startingPrice;
      }
      commit("updateEnglishAuction", updates);
      calculateStartingPrice();
    }
  );
  calculateStartingPrice();
  formData.value.yourBid = formData.value.yourMaxBid =
    unref(startingPrice) + (unref(auction).bidIncrement || 0);
  EventBus.$on(EVENT_BUS_EVENTS.NEXT_STEP, validate);
});

onBeforeUnmount(() => {
  if (onSetCurrentBidSub.value) {
    onSetCurrentBidSub.value.unsubscribe();
  }
  if (onEditAuctionSub.value) {
    onEditAuctionSub.value.unsubscribe();
  }
  EventBus.$off(EVENT_BUS_EVENTS.NEXT_STEP, validate);
});
</script>

<style scoped lang="scss">
$height: 42px;
.row > div {
  padding-top: 0;
  padding-bottom: 0;
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.headline {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding: 16px 24px 10px !important;
}

.subline {
  padding: 0 24px 20px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

:deep {
  .v-input__control {
    max-height: $height;
  }
  .v-field__field {
    max-height: $height !important;
    height: $height !important;
    & .v-field__input {
      padding: 0 12px;
      max-height: $height !important;
      height: $height !important;
      min-height: $height !important;
    }
  }
}
</style>
