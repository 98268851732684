import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

import BidFormEnglish from "@/components/BidForm/BidFormEnglish";

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaceYouBid',
  setup(__props) {

const { getters } = useStore();

const saleLoaded = computed(() => {
  return getters.saleLoaded;
});

return (_ctx: any,_cache: any) => {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (saleLoaded.value)
    ? (_openBlock(), _createBlock(_unref(BidFormEnglish), {
        key: 0,
        onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update')))
      }))
    : (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            cols: "12",
            class: "text-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                size: "48",
                indeterminate: ""
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
}
}

})