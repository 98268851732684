<template>
  <bid-form-english v-if="saleLoaded" @update="$emit('update')" />

  <v-row v-else>
    <v-col cols="12" class="text-center">
      <v-progress-circular size="48" indeterminate />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import BidFormEnglish from "@/components/BidForm/BidFormEnglish";

import { computed } from "vue";
import { useStore } from "vuex";

const { getters } = useStore();

const saleLoaded = computed(() => {
  return getters.saleLoaded;
});
</script>
