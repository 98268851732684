import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bid d-flex px-2" }

import BidStepCard from "@/components/Bid/BidStepCard";
import PlaceYouBid from "@/components/PlaceYouBid";
import BidActions from "@/components/Bid/BidActions";
import EventBus, { EVENT_BUS_EVENTS } from "@/eventBus";

import { useStore } from "vuex";
import { computed } from "vue";


const __default__ = { name: "BidComponent" };

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const { state, getters } = useStore();

function onNextClick() {
  EventBus.$emit(EVENT_BUS_EVENTS.NEXT_STEP);
}

const isMyBidCurrent = computed(() => {
  const {
    sale = {
      kind: "",
    },
  } = state ?? {};
  return sale[sale.kind]?.currentBidOwner === getters.getUserId;
});

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode(_unref(BidStepCard), { title: "Place You Bid" }, {
              default: _withCtx(() => [
                _createVNode(_unref(PlaceYouBid))
              ]),
              _: 1
            }),
            _createVNode(_unref(BidActions), {
              "is-my-bid-current": isMyBidCurrent.value,
              onNext: onNextClick
            }, null, 8, ["is-my-bid-current"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})