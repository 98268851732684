import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"




export default /*@__PURE__*/_defineComponent({
  __name: 'BidActions',
  props: {
  isMyBidCurrent: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "mt-5 px-5" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, { class: "d-flex justify-end" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: __props.isMyBidCurrent ? 'grey' : 'black',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('next')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.isMyBidCurrent ? "update" : "submit"), 1)
            ]),
            _: 1
          }, 8, ["color"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})