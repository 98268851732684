export const state = {
  loading: false,
  userInfo: {
    email: null,
    id: null,
    personalInfo: {},
  },
  agent: {},
  snackbar: {
    text: "",
    subtext: "",
    isOpened: false,
    color: "red",
    closable: false,
  },
  validationInfo: {
    files: [],
    comment: null,
    showComment: false,
  },
  saleId: null,
  sale: {},
  auction: {
    kind: "englishAuction",
    englishAuction: {
      currentBid: 1499000,
      bidStep: 500,
      endDate: "2021-01-01 00:00:00",
    },
  },
};
