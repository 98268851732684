import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center pl-6" }
const _hoisted_2 = {
  key: 1,
  class: "d-flex align-center justify-center h-full"
}
const _hoisted_3 = {
  key: 0,
  class: "d-block text-small"
}

import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const auctionHasFinished = ref<Boolean>(false);
const { state, commit, dispatch } = useStore();

const title = computed(() => {
  const result = state.auction.kind.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
});
const snackbar = computed(() => {
  return state.snackbar;
});

function onClose() {
  dispatch("close");
}

function closeSnackbar() {
  commit("closeSnackbar");
}

function setCredentials(credentials: { [x: string]: string }) {
  Object.keys(credentials).forEach((key) => {
    localStorage.setItem(key, credentials[key]);
  });

  dispatch("fetchUserInfo");
}

onMounted(() => {
  window.addEventListener("message", (ev) => {
    const action = ev?.data?.action;

    switch (action) {
      case "credentials":
        setCredentials(ev.data.data);
        break;
      case "auction-info":
        commit("setAuctionInfo", ev.data.data);
        dispatch("fetchSale");
        break;
      case "auction-has-finished":
        auctionHasFinished.value = true;
        commit("errorNotification", {
          text:
            "The auction has already finished. You are no longer able to submit a bid for this asset. " +
            "Please contact listing agent for more details.",
        });
        setTimeout(() => {
          dispatch("close");
        }, 5000);
        break;
    }
  });
});

return (_ctx: any,_cache: any) => {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        app: "",
        dark: "",
        color: "black"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h2", null, _toDisplayString(title.value), 1)
          ]),
          _createVNode(_component_v_spacer),
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: onClose
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" mdi-close ")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          (_unref(state).userInfo.email)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_v_progress_circular, {
                  indeterminate: "",
                  size: "48"
                })
              ])),
          _createVNode(_component_v_overlay, {
            class: "align-center justify-center",
            "model-value": _unref(state).loading || auctionHasFinished.value
          }, {
            default: _withCtx(() => [
              (_unref(state).loading)
                ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                    key: 0,
                    color: "primary",
                    indeterminate: "",
                    size: "48"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_snackbar, {
        modelValue: snackbar.value.isOpened,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((snackbar.value.isOpened) = $event)),
        timeout: 5000,
        bottom: "",
        color: snackbar.value.color
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: closeSnackbar
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(snackbar.value.text) + " ", 1),
          (snackbar.value.subtext)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(snackbar.value.subtext), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ]),
    _: 1
  }))
}
}

})